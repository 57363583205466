



































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Routes } from '@rs-911/router/routes';

@Component
export default class Portal extends Vue {
  routes = Routes;
  @Prop() private msg!: string;

  created() {
    // if(this.$router.currentRoute.name !== 'MailConfirmation') {
    //   this.$store.dispatch('auth/getUserInfo');
    // }
    
  }
}
